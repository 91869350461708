.new-staff-container {
  width: 40rem;
  min-height: 20rem;
  max-height: 90vh;
  overflow: auto;
  background: white;
  border-radius: 6px;
  border: 2px solid black;
  background-color: #24242c;
  @media (max-width: 768px) {
    width: 100%;
    height: 100%;
  }
}

.new-staff-header {
  position: fixed;
  width: 100%;
  background-color: #20202a;
  border-bottom: 7px solid #35343a;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.search-phone {
  background-color: #24242c;
}

.search-phone-input {
  width: 93.2%;
}

.user-preview-container {
  background-color: #35343a;
  padding-top: 1rem;
  padding-bottom: 1rem;
  text-align: center;
  align-content: center;
  margin: 0 auto;
  height: auto;
  border-radius: 0px 0px 3px 3px;
}

.users-container {
  min-height: 18rem;
  max-height: 18rem;
  background-color: #35343a;
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  @media (max-width: 768px) {
    min-height: calc(100vh - 12rem);
  }
}

.button-add {
  margin: 0 auto;
  margin-top: 1rem;
}

.user-avatar-no-src {
  margin: auto;
}

.new-staff-container {
  color: white;
}

.new-staff-spinner-container {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding-bottom: 2rem;
}

.new-staff-spinner {
  color: #4f5d73;
}

.text-area-details {
  height: 5rem;
}

.new-group-container {
  width: 40rem;
  min-height: 10rem;
  background: white;
  border-radius: 6px;
  border: 2px solid black;
  background-color: #24242c;
}

.battery-status-container {
  min-height: auto;
}