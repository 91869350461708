.sidebar {
  .logo {
    background-color: #24242c;
    // margin-bottom: -8px;
    max-height: 57px; // to fit the rest of the nav bar height

    img {
      // max-height: 1em;
    }
  }
}



.sidebar-card::-webkit-scrollbar {
  width: 5px;
  border-radius: 0px;
}

.sidebar-card::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.sidebar-card::-webkit-scrollbar-thumb {
  background: #6b6b6b;
  border: 0px;
  border-radius: 0px;
}

.sidebar-card::-webkit-scrollbar-thumb:hover {
  background: #555;
}