.users-container {
    min-height: 25rem;
    max-height: 100%;
    background-color: #35343a;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    margin-top: 50px;
}

.mt-50 {
    margin-top: 50px !important;
}

.users-edit-container {
    min-height: 30rem;
    max-height: 30rem;
    background-color: #35343a;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
    /* Firefox */
}