.bg-dark1 {
  background-color: #2c2d32;
}

.bg-dark2 {
  background-color: #24242c;
}

.bg-dark3 {
  background-color: #20202a;
}

.bg-dark4 {
  background-color: #36373c;
}

.bg-dark-sidebar {
  background-color: #2eb85c;
  color: white !important;
}

.bg-main {
  background-color: #0b0b17;
}

.text-color {
  color: #51fffe;
}

.text-color2 {
  color: #b6fbfe;
}

.bg-standard {
  background-color: #1399AD;
}

.bg-custom {
  background-color: #2eb85c;
}

.default-layout {
  display: flex;
  overflow-y: hidden;
  height: 100%;
}

.main-layout {
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: none;
  flex: 1;
}

p {
  padding: 0;
  margin: 0;
}

body>#root>div {
  height: 100vh;
}

*::-webkit-scrollbar {
  width: 16px;
}

*::-webkit-scrollbar-track {
  background: #7e7e7e;
}

*::-webkit-scrollbar-thumb {
  background-color: #20202a;
  border-radius: 10px;
  border: 3px solid #35343a;
}

.over-flow-auto {
  overflow-y: auto;
}

.over-flow-hidden {
  overflow-y: hidden;
}

// multiselect dropdown
.multiselect-container .optionListContainer .optionContainer .option {
  color: #999;
}

.multiselect-container .optionListContainer .optionContainer .option.highlightOption {
  color: #fff !important;
}

.multiSelectContainer li:hover {
  color: #fff !important;
}



@media only screen and (min-width: 767px) and (max-width: 1100px) {

  .table-size {
    width: 1000px !important;
  }

  .app-user-table-size {
    width: 1100px !important;
  }

  .overflowX {
    overflow-x: auto;
  }
}

.w-13 {
  width: 13% !important;
}


.multiselect-container .optionListContainer .optionContainer .option {
  color: #999;
  background-color: #0B0B17 !important;
  border: 0px !important;
}

.avatar-img {
  width: 100%;
  height: 100% !important;
  border-radius: 50em;
  object-fit: cover !important;
}


.PhoneInputInput {
  flex: 1 1;
  min-width: 0;
  background-color: transparent !important;
  border-radius: 3px !important;
  color: #51FFFE;
  height: 2.2rem !important;
}

.PhoneInputInput:focus-visible {
  outline: none !important;
}


select {
  background-color: transparent !important;
  /* This styles the background of the select box */
  color: white;
}

option {
  background-color: #333;
  /* Background color for options */
  color: white;
  /* Text color for options */
}


.form-select:focus-visible {
  outline: none !important;
}

.form-select:focus {
  border-color: none;
  outline: 0;
  box-shadow: none !important;
}

.id-dropdown ul li {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 0px !important;
}

.id-dropdown ul {
  padding-left: 0px !important;
  margin-left: 5px !important;
}

.li-hover:hover {
  background-color: #20202A;
  cursor: pointer;
}


.sound-dropdown .dropdown-toggle::after {
  display: inline-block;
  margin-left: 9.255em !important;
  vertical-align: .255em;
  content: "";
  border-top: .3em solid;
  border-right: .3em solid transparent;
  border-bottom: 0;
  border-left: .3em solid transparent;
}


.template-select optgroup {
  background-color: rgb(69, 211, 86) !important;
}


.parent .form-switch .form-check-input {
  width: 50px;
  margin-left: -3.5em;
  background-position: left center;
  transition: background-position 0.15s ease-in-out;
  height: 24px;
  border: none;
}

.parent .form-switch .form-check-input:checked {
  background-color: green;
  border-color: green;
}

.form-switch .form-check-input:checked {
  background-color: green !important;
  border-color: green !important;
}

.form-switch .form-check-input:checked {
  background-position: right center !important;
}

.parent .form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: var(--cui-form-check-input-bg, #fff);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  appearance: none;
}

.form-switch .form-check-input {

  border: none;
}

.parent .form-check-input:focus {
  outline: 0 !important;
  box-shadow: none !important;
  border: none !important;
}

.form-check-input:focus {
  outline: 0 !important;
  box-shadow: none !important;
  border: none !important;
}

.parent .form-check-label {
  color: white;
  font-size: 22px;
}



.is-hover:hover {
  color: #39BC64;
  cursor: pointer;
}

.is-hover {
  color: #EBEDEF;
  cursor: pointer;
}

.btn-group>.btn:focus {
  z-index: 1;
  box-shadow: none !important;
}


.word-break {
  max-width: 20ch;
  word-wrap: break-word;
}


.alert-card::-webkit-scrollbar {
  display: none;
}

.alert-card::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.alert-card::-webkit-scrollbar-thumb {
  background: #888;
}

.alert-card::-webkit-scrollbar-thumb:hover {
  background: #555;
}




/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border: 0px;
  border-radius: 0px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.text-break {
  word-break: break-all;
}



.form-select:disabled {
  color: #b4afaf !important;
  background-color: var(--cui-form-select-disabled-bg, #d8dbe0);
}